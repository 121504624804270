<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="queryForm" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="名称">
            <a-input v-model="queryForm.customer_name" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord(record)">删除</a>
      </div>
    </a-table>
    <a-modal :width="650" v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="formModel"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="客户名称" prop="customer_id">
          <a-select
            showSearch
            label-in-value
            allowClear
            placeholder="请选择客户"
            style="width: 360px"
            :value="customerInfo"
            :filter-option="false"
            :not-found-content="customerFetching ? undefined : null"
            @search="getCustomerInfo"
            @change="changeCustomerInfo"
          >
            <template v-if="customerFetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开票后付款天数" prop="charge_deadline_day">
          <a-input v-model="formModel.charge_deadline_day" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import {
  saveCustomerDeadline,
  deleteCustomerDeadline,
  getCustomer,
  queryCustomerDeadlineList
} from '@/api/common'
import debounce from 'lodash/debounce'
export default {
  data() {
    this.lastFetchId = 0
    this.getCustomerInfo = debounce(this.getCustomerInfo, 400)
    return {
      labelCol: { span: 5 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      queryForm: {
        customer_name: '',
        department: this.$store.getters.userInfo.department
      },
      formModel: {
        id: null,
        department: this.$store.getters.userInfo.department,
        customer_id: null,
        charge_deadline_day: null
      },
      rules: {
        charge_deadline_day: [{ required: true, message: '请填写开票后付款天数', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text) => {
            if (text === 0) return '-'
            if (text === 100) return '混合部门'
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name',
          align: 'center'
        },
        {
          title: '开票后延期天数',
          dataIndex: 'charge_deadline_day',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: 300,
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增客户开票后付款天数',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'customer_info',
      customerInfo: undefined,
      customerId: null,
      customerFetching: false,
      customerOps: []
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    getCustomerInfo(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.payerCustomerOps = []
      this.payerFetching = true
      getCustomer({
        department: this.$store.getters.userInfo.department,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.customerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
          this.customerId = res[0].value
          this.formModel.customer_id = res[0].value
        } else {
          this.customerInfo = undefined
          this.customerId = null
          this.formModel.customer_id = null
        }
        this.customerOps = res
        this.customerFetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.customerId = null;
        this.formModel.customer_id = null
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.customerId = value.key;
        this.formModel.customer_id = value.key;
      }
      this.customerOps = []
      this.customerFetching = false
    },
    getInfo(param) {
      queryCustomerDeadlineList({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.records
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.queryForm))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.queryForm))
    },
    newRecord() {
      this.modelTitle = '新增客户开票后付款天数'
      this.visible = true
      this.customerInfo = undefined
      this.formModel = {
        id: null,
        department: this.$store.getters.userInfo.department,
        customer_id: null,
        charge_deadline_day: null
      }
    },
    delRecord(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteCustomerDeadline({ id: record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    modifyRecord(record) {
      this.modelTitle = '修改客户开票后付款天数'
      this.visible = true
      this.formModel = Object.assign({}, record)
      this.customerInfo = {
        'key': record.customer_id, 'label': record.customer_name
      }
    },
    handleSave() {
      this.$refs.formModel.validate(valid => {
        if (valid) {
          this.saveInfo()
        } else {
          return false
        }
      })
    },
    saveInfo() {
      saveCustomerDeadline(this.formModel).then(res => {
        if (res) {
          this.$message.success('保存成功!')
          const param = Object.assign({}, this.queryForm)
          param.page = this.pagination.current
          param.pageSize = this.pagination.pageSize
          this.getInfo(param)
          this.visible = false
        } else {
          this.$message.error('保存失败!')
        }
      })
    },
    handleCancel(e) {
      this.$refs.formModel.resetFields()
      this.visible = false
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
